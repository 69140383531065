import { HelpBlock, Alert, Button } from '@devstart/react-bootstrap';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import type { TFunction } from 'i18next';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import isEmail from 'validator/lib/isEmail';
import ConfirmChangeEmailModal from '../../templates/Challenges/components/confirm-change-email-modal';

import CustomButton from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';

import { maybeEmailRE } from '../../utils';

import FullWidthRow from '../helpers/full-width-row';
import Input from '../ui/Input';

import './email.css';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

type EmailProps = {
  readonly email: string;
  readonly isEmailVerified: boolean;
  readonly t: TFunction;
};

interface EmailForm {
  currentEmail: string;
  newEmail: string;
  confirmNewEmail: string;
  isPristine: boolean;
}

function EmailSettings({ email, isEmailVerified, t }: EmailProps): JSX.Element {
  const [emailForm, setEmailForm] = useState<EmailForm>({
    currentEmail: email,
    newEmail: '',
    confirmNewEmail: '',
    isPristine: true
  });

  function createHandleEmailFormChange(
    key: 'newEmail' | 'confirmNewEmail'
  ): (e: React.ChangeEvent<HTMLInputElement>) => void {
    return e => {
      e.preventDefault();
      const userInput = e.target.value.slice();
      setEmailForm(prev => ({
        ...prev,
        [key]: userInput,
        isPristine: userInput === prev.currentEmail
      }));
    };
  }

  function getValidationForNewEmail() {
    const { newEmail, currentEmail } = emailForm;
    if (!maybeEmailRE.test(newEmail)) {
      return {
        state: null,
        message: ''
      };
    }
    if (newEmail === currentEmail) {
      return {
        state: 'error',
        message: t('validation.same-email')
      };
    }
    if (isEmail(newEmail)) {
      return { state: 'success', message: '' };
    } else {
      return {
        state: 'error',
        message: t('validation.invalid-email')
      };
    }
  }

  function getValidationForConfirmEmail() {
    const { confirmNewEmail, newEmail } = emailForm;
    if (!maybeEmailRE.test(newEmail)) {
      return {
        state: null,
        message: ''
      };
    }
    const isMatch = newEmail === confirmNewEmail;
    if (maybeEmailRE.test(confirmNewEmail)) {
      return {
        state: isMatch ? 'success' : 'error',
        message: isMatch ? '' : t('validation.email-mismatch')
      };
    } else {
      return {
        state: null,
        message: ''
      };
    }
  }
  const [confirmChangeEmailModal, setConfirmChangeEmailModal] = useState(false);
  const { newEmail, confirmNewEmail, currentEmail, isPristine } = emailForm;

  function toggleConfirmChangeEmailModal(): void {
    setConfirmChangeEmailModal(prev => !prev);
  }
  const { state: newEmailValidation, message: newEmailValidationMessage } =
    getValidationForNewEmail();

  const {
    state: confirmEmailValidation,
    message: confirmEmailValidationMessage
  } = getValidationForConfirmEmail();
  const isDisabled =
    newEmailValidation !== 'success' ||
    confirmEmailValidation !== 'success' ||
    isPristine;
  const ariaLabel = t('settings.email.heading');
  if (!currentEmail) {
    return (
      <div>
        <FullWidthRow>
          <p className='large-p text-center'>{t('settings.email.missing')}</p>
        </FullWidthRow>
        <FullWidthRow>
          <Link style={{ textDecoration: 'none' }} to='/update-email'>
            <Button block={true} bsSize='lg' bsStyle='primary'>
              {t('buttons.edit')}
            </Button>
          </Link>
        </FullWidthRow>
      </div>
    );
  }
  return (
    <div className='email-settings'>
      <div className='email-container'>
        <ConfirmChangeEmailModal
          onHide={toggleConfirmChangeEmailModal}
          show={confirmChangeEmailModal}
          newEmail={confirmNewEmail}
          currentEmail={currentEmail}
        />

        <form
          id='form-update-email'
          className='form-update-email'
          {...{ onSubmit: e => e.preventDefault() }}
        >
          <h4 className='section-header-text' id='email-settings'>
            Configurações de e-mail
          </h4>

          {!isEmailVerified && (
            <div className='email-container'>
              <HelpBlock>
                <Alert
                  bsStyle='info'
                  className='text-center'
                  closeLabel={t('buttons.close')}
                >
                  {t('settings.email.not-verified')}
                  <br />
                  <Trans i18nKey='settings.email.check'>
                    <Link to='/update-email' />
                  </Trans>
                </Alert>
              </HelpBlock>
            </div>
          )}

          <div className='group-form' aria-label={ariaLabel}>
            <Input
              controlId='current-email'
              disabled
              value={currentEmail}
              label={t('settings.email.current')}
            />

            <Input
              controlId='new-email'
              validationState={newEmailValidation}
              label={t('settings.email.new')}
              onChange={createHandleEmailFormChange('newEmail')}
              type='email'
              value={newEmail}
              hasError={!!newEmailValidationMessage}
              error={newEmailValidationMessage}
            />

            <Input
              controlId='confirm-email'
              validationState={newEmailValidation}
              label={t('settings.email.confirm')}
              onChange={createHandleEmailFormChange('confirmNewEmail')}
              type='email'
              value={confirmNewEmail}
              hasError={!!confirmEmailValidationMessage}
              error={confirmEmailValidationMessage}
            />
          </div>

          <CustomButton
            buttonType={ButtonTypes.Primary}
            aria-disabled={isDisabled}
            //type='submit'
            onClick={() => !isDisabled && toggleConfirmChangeEmailModal()}
            width='100%'
            {...(isDisabled && { tabIndex: -1 })}
            style={{ margin: '0 auto' }}
            disabled={isDisabled}
          >
            {t('buttons.save')}
            <span className='sr-only'>{t('settings.email.heading')}</span>
          </CustomButton>
        </form>
      </div>
    </div>
  );
}

EmailSettings.displayName = 'EmailSettings';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EmailSettings));
