import { Grid, Row } from '@devstart/react-bootstrap';
import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ChangePassword from '@components/settings/change-password';
import CreatePassword from '@components/settings/create-password';
import {
  createFlashMessage,
  removeFlashMessage
} from '../components/Flash/redux';
import { Loader, Spacer } from '../components/helpers';
import About from '../components/settings/about';
import DangerZone from '../components/settings/danger-zone';
import Email from '../components/settings/email';
import { Themes, type ThemeProps } from '../components/settings/theme';
import { hardGoTo as navigate } from '../redux/actions';
import { User } from '../redux/prop-types';
import {
  isSignedInSelector,
  signInLoadingSelector,
  userSelector
} from '../redux/selectors';
import {
  submitNewAbout,
  updateMyKeyboardShortcuts,
  updateMySound,
  updateMyTheme
} from '../redux/settings/actions';
import './show-settings.css';
import BreadCrumb from '../templates/Challenges/components/bread-crumb';
import UsernameSettings from '../components/settings/username';

type ShowSettingsProps = Pick<ThemeProps, 'toggleNightMode'> & {
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
  isSignedIn: boolean;
  navigate: (location: string) => void;
  showLoading: boolean;
  submitNewAbout: () => void;
  user: User;
};

const mapStateToProps = createSelector(
  signInLoadingSelector,
  userSelector,
  isSignedInSelector,
  (showLoading: boolean, user: User, isSignedIn) => ({
    showLoading,
    user,
    isSignedIn
  })
);

const mapDispatchToProps = {
  createFlashMessage,
  removeFlashMessage,
  navigate,
  submitNewAbout,
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  toggleSoundMode: (sound: boolean) => updateMySound({ sound }),
  toggleKeyboardShortcuts: (keyboardShortcuts: boolean) =>
    updateMyKeyboardShortcuts({ keyboardShortcuts })
};

export function ShowSettings(props: ShowSettingsProps): JSX.Element {
  const { t } = useTranslation();
  const {
    isSignedIn,
    user: {
      email,
      isEmailVerified,
      username,
      location,
      name,
      cpf,
      phone,
      hasPassword
    },
    showLoading,
    createFlashMessage,
    removeFlashMessage,
    submitNewAbout,
    navigate
  } = props;
  const isSignedInRef = useRef(isSignedIn);

  if (showLoading) {
    return <Loader fullScreen={true} />;
  }

  if (!isSignedInRef.current) {
    navigate('/');
    return <Loader fullScreen={true} />;
  }

  const breadCrumbItems = [
    {
      key: 'profile',
      value: 'Perfil',
      redirectsTo: '/settings'
    }
  ];

  return (
    <>
      <Helmet title={`${t('buttons.settings')} | DEVstart`} />

      <BreadCrumb breadCrumbItems={breadCrumbItems} />

      <Grid className='settings-wrapper'>
        <Row>
          <main className='container-settings'>
            <h1
              className='title-section'
              style={{ overflowWrap: 'break-word' }}
            >
              {t('buttons.profile')}
            </h1>

            <div className='forms-section'>
              <UsernameSettings username={username} />

              <About
                location={location}
                name={name}
                cpf={cpf}
                phone={phone}
                submitNewAbout={submitNewAbout}
                createFlashMessage={createFlashMessage}
                removeFlashMessage={removeFlashMessage}
              />

              <Email email={email} isEmailVerified={isEmailVerified} />

              <div className='email-container'>
                {hasPassword ? <ChangePassword /> : <CreatePassword />}
              </div>

              <DangerZone />
            </div>
          </main>
        </Row>
      </Grid>
      <Spacer size='medium' />
    </>
  );
}

ShowSettings.displayName = 'ShowSettings';

export default connect(mapStateToProps, mapDispatchToProps)(ShowSettings);
