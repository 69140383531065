import React, { Component } from 'react';

import type { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { createFlashMessage, removeFlashMessage } from '../Flash/redux';
import { FlashMessages } from '../Flash/redux/flash-messages';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import Input from '../ui/Input';

import './about.css';

type AboutProps = {
  submitNewAbout: (formValues: FormValues) => void;
  t: TFunction;
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
  name: string;
  location: string;
  cpf: string;
  phone: string;
};

type FormValues = Pick<AboutProps, 'name' | 'location' | 'cpf' | 'phone'>;

type AboutState = {
  formValues: FormValues;
  originalValues: FormValues;
  formClicked: boolean;
  inputPhoneHandler: string;
};

class AboutSettings extends Component<AboutProps, AboutState> {
  public static readonly displayName: string = 'AboutSettings';
  constructor(props: AboutProps) {
    super(props);
    const { name = '', location = '', cpf = '', phone = '' } = props;
    const values = {
      name,
      cpf,
      phone,
      location
    };
    this.state = {
      formValues: { ...values },
      originalValues: { ...values },
      formClicked: false,
      inputPhoneHandler: 'form-control'
    };
  }

  componentDidUpdate() {
    const { name, location, cpf, phone } = this.props;
    const { formValues, formClicked } = this.state;
    if (
      formClicked &&
      name === formValues.name &&
      cpf === formValues.cpf &&
      phone === formValues.phone &&
      location === formValues.location
    ) {
      return this.setState({
        originalValues: {
          name,
          cpf,
          phone,
          location
        },
        formClicked: false
      });
    }
    return null;
  }

  isFormPristine = () => {
    const { formValues, originalValues } = this.state;
    return (Object.keys(originalValues) as Array<keyof FormValues>)
      .map(key => originalValues[key] === formValues[key])
      .every(bool => bool);
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { formValues } = this.state;

    this.props.removeFlashMessage();

    const validation = this.isEmptyField(formValues);
    if (validation.result) {
      this.setErrorClassInput(validation.field);
      this.props.createFlashMessage({
        message: validation.message,
        type: 'danger'
      });
      return;
    }

    const { submitNewAbout } = this.props;
    return this.setState({ formClicked: true }, () =>
      submitNewAbout(this.state.formValues)
    );
  };

  handlePhoneChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return this.setState(state => ({
      inputPhoneHandler: 'form-control',
      formValues: {
        ...state.formValues,
        phone: value
      }
    }));
  };

  handleLocationChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return this.setState(state => ({
      formValues: {
        ...state.formValues,
        location: value
      }
    }));
  };

  isEmptyField = (
    obj: FormValues
  ): {
    result: boolean;
    field: string;
    message: FlashMessages;
  } => {
    if (obj.name === '') {
      return {
        result: true,
        field: 'name',
        message: FlashMessages.NameIsRequired
      };
    } else if (obj.phone === '' || this.formatPhoneValue(obj.phone)) {
      return {
        result: true,
        field: 'phone',
        message: FlashMessages.InvalidPhone
      };
    } else if (obj.location === '') {
      return {
        result: true,
        field: 'location',
        message: FlashMessages.AddressIsRequired
      };
    } else {
      return { result: false, field: 'none', message: FlashMessages.None };
    }
  };

  formatPhoneValue = (value: string) => {
    const phoneWithoutMask: string = value
      .replace(/ /g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .replace(/_/g, '');
    return phoneWithoutMask.length < 11;
  };

  setErrorClassInput = (input: string) => {
    if (input === 'phone') {
      this.setState({ inputPhoneHandler: 'form-control form-control-error' });
    }
  };

  render() {
    const {
      formValues: { name, cpf, phone, location }
    } = this.state;
    const { t } = this.props;
    const ariaLabel = t('settings.headings.personal-info');
    return (
      <div className='about-settings'>
        <div>
          <form id='camper-identity' onSubmit={this.handleSubmit}>
            <h4 className='section-header-text' id='personal-data'>
              Dados pessoais
            </h4>

            <div className='group-form' aria-label={ariaLabel}>
              <Input
                controlId='about-name'
                label={t('settings.labels.name') ?? undefined}
                value={name}
                disabled
              />

              <Input
                controlId='about-phone'
                label={t('settings.labels.phone') ?? undefined}
                onChange={this.handlePhoneChange}
                value={phone}
                hasMask
                maskValue='(99) 99999-9999'
              />

              <Input
                controlId='about-cpf'
                label={t('settings.labels.codePerson') ?? undefined}
                value={cpf}
                hasMask
                maskValue='999.999.999-99'
                disabled
              />

              <Input
                controlId='about-location'
                label={t('settings.labels.location') ?? undefined}
                onChange={this.handleLocationChange}
                value={location}
              />
            </div>

            <Button
              buttonType={ButtonTypes.Primary}
              disabled={this.isFormPristine()}
              aria-disabled={this.isFormPristine()}
              type='submit'
              style={{ margin: '0 auto' }}
              {...(this.isFormPristine() && { tabIndex: -1 })}
              isLoading={this.state.formClicked}
            >
              {t('buttons.save')}
              <span className='sr-only'>
                {t('settings.headings.personal-info')}
              </span>
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AboutSettings);
