import React, { useEffect, useState } from 'react';
import './confirm-change-email-modal.css';
import { FormGroup } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateMyEmail } from '@redux/settings/actions';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forgotPassword } from '@redux/actions';
import { createSelector } from 'reselect';
import { User } from '@redux/prop-types';
import {
  selectSentEmailToRecoveryLoading,
  userSelector
} from '@redux/selectors';
import { updateMyEmailLoadingSelector } from '@redux/settings/selectors';
import InputPassword from '@ui/input-password';
import { Modal } from '../../../components/ui/Modal';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';

const mapStateToProps = createSelector(
  userSelector,
  selectSentEmailToRecoveryLoading,
  updateMyEmailLoadingSelector,
  (
    user: User,
    sentEmailToRecoveryLoading: boolean,
    updateMyEmailLoading: boolean
  ) => ({
    user,
    sentEmailToRecoveryLoading,
    updateMyEmailLoading
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ updateMyEmail, forgotPassword }, dispatch);

type ConfirmChangeEmailModalProps = {
  readonly onHide: () => void;
  readonly show: boolean;
  readonly newEmail: string;
  readonly currentEmail: string;
  readonly updateMyEmail: (data: {
    email: string;
    password: string;
    isCreatingPassword: boolean;
  }) => void;
  readonly forgotPassword: (formValues: { email: string }) => void;
  readonly user: User;
  readonly sentEmailToRecoveryLoading: boolean;
  readonly updateMyEmailLoading: boolean;
};

function ConfirmChangeEmailModal(
  props: ConfirmChangeEmailModalProps
): JSX.Element {
  const {
    show,
    onHide,
    newEmail,
    currentEmail,
    updateMyEmail,
    forgotPassword,
    user,
    sentEmailToRecoveryLoading,
    updateMyEmailLoading
  } = props;
  const { t } = useTranslation();

  const [userPassword, setUserPassword] = useState('');
  const [newPassword, setNewPassword] = useState({ value: '', error: '' });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: '',
    error: ''
  });

  useEffect(() => {
    setUserPassword('');
    setNewPassword({ value: '', error: '' });
    setConfirmNewPassword({ value: '', error: '' });
  }, [show]);

  const handleForgotPassword = (): void => {
    forgotPassword({ email: currentEmail });
  };

  const validatePassword = (): boolean => {
    if (user.hasPassword) {
      return !!userPassword;
    } else {
      if (newPassword.value.length < 8 || confirmNewPassword.value.length < 8) {
        setNewPassword(old => ({
          ...old,
          error: 'Senha deve ter no mínimo 8 caracteres'
        }));

        setConfirmNewPassword(old => ({
          ...old,
          error: 'Senha deve ter no mínimo 8 caracteres'
        }));
        return false;
      }
      if (newPassword.value !== confirmNewPassword.value) {
        setNewPassword(old => ({
          ...old,
          error: 'As duas senhas devem ser iguais'
        }));

        setConfirmNewPassword(old => ({
          ...old,
          error: 'As duas senhas devem ser iguais'
        }));
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const isValidPassword = validatePassword();

    if (isValidPassword) {
      updateMyEmail({
        email: newEmail,
        password: user.hasPassword ? userPassword : confirmNewPassword.value,
        isCreatingPassword: !user.hasPassword
      });
    }
  };

  return (
    <Modal.Root
      animation={false}
      keyboard={true}
      onHide={props.onHide}
      show={show}
      backdrop='static'
    >
      <Modal.Header closeButton onHide={onHide}>
        <p>{t('settings.email.confirm-change')}</p>
      </Modal.Header>

      <form id='confirm-change-email' onSubmit={e => handleSubmit(e)}>
        <Modal.Body>
          <p style={{}}>
            {t('settings.email.new')}: {newEmail}
          </p>
          {user.hasPassword ? (
            <FormGroup controlId='confirm-password'>
              <InputPassword
                id='confirm-password'
                label='Senha'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUserPassword(e.target.value)
                }
                value={userPassword}
              />
              <Button
                buttonType={ButtonTypes.Tertiary}
                onClick={handleForgotPassword}
                isLoading={sentEmailToRecoveryLoading}
                width='200px'
              >
                REDEFINIR SENHA
              </Button>
            </FormGroup>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <p style={{ marginBottom: 16 }}>
                {t('settings.email.new-password')}
              </p>
              <FormGroup controlId='new-password'>
                <InputPassword
                  id='new-password'
                  label='Nova senha'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewPassword({
                      value: e.target.value,
                      error: ''
                    })
                  }
                  value={newPassword.value}
                  hasError={!!newPassword.error}
                  messageError={newPassword.error}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: 0 }}
                controlId='confirm-new-password'
              >
                <InputPassword
                  id='confirm-new-password'
                  label='Confirme a nova senha'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setConfirmNewPassword({
                      value: e.target.value,
                      error: ''
                    })
                  }
                  value={confirmNewPassword.value}
                  hasError={!!confirmNewPassword.error}
                  messageError={confirmNewPassword.error}
                />
              </FormGroup>
            </div>
          )}
          <p>{t('settings.email.warning-change-email')}</p>
          <p>{t('settings.email.warning-change-email-2')}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide} buttonType={ButtonTypes.Secondary}>
            {t('buttons.cancel')}
          </Button>
          <Button
            buttonType={ButtonTypes.Primary}
            type='submit'
            disabled={
              updateMyEmailLoading ||
              (user.hasPassword &&
                (!userPassword || userPassword.length < 8)) ||
              (!user.hasPassword &&
                (newPassword.value.length < 8 ||
                  confirmNewPassword.value.length < 8 ||
                  newPassword.value !== confirmNewPassword.value))
            }
            form='confirm-change-email'
            isLoading={updateMyEmailLoading}
            width='140px'
          >
            {t('settings.buttons.confirm')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmChangeEmailModal);
